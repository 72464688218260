import { all } from "redux-saga/effects";
import inventory from "./inventory";
import loading from "./loading";
import sequence from "./sequence";
import meta from "./meta";
import authentication from "./authentication";
import toast from "./toast";
export default function* rootSaga() {
  yield all([
    inventory(),
    loading(),
    sequence(),
    meta(),
    authentication(),
    toast(),
  ]);
}
