import React, { FC, memo, useEffect, useState } from 'react';
import styles from './ToastMessageItem.module.scss';
import { Toast, ToastMessageStatus, ToastMessageTypes } from '../../../store/toast';
import { animated, useSpring } from 'react-spring';
import classNames from 'classnames';

interface Props {
  y: number;
}

const height = 37 + 10;

const ToastMessageItem: FC<Props & Toast> = memo(({ id, type, status, message, y }) => {
  const isHide = status === ToastMessageStatus.Hide;

  const [localY, setY] = useState(-height);
  const toastMessageItemProps = useSpring({ xy: [isHide ? 100 : 0, localY], opacity: isHide ? 0 : 1 });

  useEffect(() => {
    setY(y * height);
  }, [y]);

  return (
    <animated.div
      style={{
        opacity: toastMessageItemProps.opacity,
        transform: (toastMessageItemProps.xy.interpolate as any)((x: number, y: number) => {
          return `translate(${x}%, ${y * -1}px)`;
        })
      }}
      className={classNames(
        styles.toastMessageItem,
        type === ToastMessageTypes.Success && styles.isSuccess,
        type === ToastMessageTypes.Warning && styles.isWarning,
        type === ToastMessageTypes.Error && styles.isError
      )}
    >
      {message}
    </animated.div>
  );
});

export default ToastMessageItem;
