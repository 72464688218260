import React, { useState } from "react";
import FieldMenu from "./FieldMenu";
import Ink from "react-ink";
import styled from "styled-components/macro";
import { FieldConfig } from "./FieldWrapper";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { InventoryActions } from "../../store/inventory";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { RiFilter2Fill } from "react-icons/ri";
import size from "lodash/size";
import { mileageFilters } from "./Filters";

interface Props {
  config: FieldConfig;
  model: string;
}

const Container = styled.th`
  background: ${({ theme }) => theme.color.secondary1};
  position: sticky;
  top: 0;
  z-index: 1;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.color.secondary2};
  }
`;

const Label = styled.div`
  cursor: pointer;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: relative;
  transition: background-color ease-in-out 0.15s;
  &:hover {
    background: ${({ theme }) => theme.color.secondary2};
  }
`;

const FilterIconWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-45%);
`;

const SortIconWrapper = styled.div``;
const Field: React.FC<Props> = ({ config, model }) => {
  const [isVisibleFieldMenu, setVisibleFieldMenu] = useState(false);
  const fieldKey = get(config, "fieldKey");
  const label = get(config, "label");
  const filterEnabled = get(config, "filterEnabled");
  const dispatch = useDispatch();

  const { filter, selectedSort, selectedFilter } = useTypedSelector(
    ({ inventory: { filter, selectedSort, selectedFilter } }) => {
      return {
        filter,
        selectedSort,
        selectedFilter,
      };
    }
  );

  const sortedKey = get(selectedSort, [model, "key"]);
  const sort = get(selectedSort, [model, "sort"]);

  const filterFieldKeys = get(filter, [model, fieldKey]);
  const selectedFilterFieldKeys = get(selectedFilter, [model, fieldKey]);

  const renderFilterIcon = () => {
    if (fieldKey === "mileage") {
      if (size(selectedFilterFieldKeys) === size(mileageFilters)) {
        return null;
      }
    } else {
      if (size(selectedFilterFieldKeys) === size(filterFieldKeys)) {
        return null;
      }
    }

    return (
      <FilterIconWrapper>
        <RiFilter2Fill size={16} color="#50e3c2" />
      </FilterIconWrapper>
    );
  };
  const onClickField = () => {
    if (!isVisibleFieldMenu && filterEnabled) {
      if (!filterFieldKeys && fieldKey !== "mileage") {
        dispatch(InventoryActions.getFilter(model, fieldKey));
      }
    }
    setVisibleFieldMenu((prev) => !prev);
  };

  const renderSortIcon = () => {
    return sort === "asc" ? (
      <BiUpArrowAlt size={17} color="#50e3c2" />
    ) : (
      <BiDownArrowAlt size={17} color="#50e3c2" />
    );
  };
  return (
    <Container style={{ width: config.width }}>
      <Label onClick={onClickField}>
        {label}
        {sortedKey === fieldKey && (
          <SortIconWrapper>{renderSortIcon()}</SortIconWrapper>
        )}
        {selectedFilterFieldKeys && renderFilterIcon()}
        <Ink />
      </Label>
      {isVisibleFieldMenu && (
        <FieldMenu
          model={model}
          fieldKey={fieldKey}
          onClose={() => setVisibleFieldMenu(false)}
          filterEnabled={filterEnabled}
        />
      )}
    </Container>
  );
};

export default Field;
