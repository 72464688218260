import React from "react";
import styled from "styled-components/macro";
import CarMetaForm from "./CarMetaForm/CarMetaForm";
import InventorySummary from "./InventorySummary";

const Container = styled.div`
  min-width: 300px;
  width: 300px;
  min-height: calc(100vh - 85px);
  margin-right: 20px;
  margin-top: 20px;
`;
const Sidebar: React.FC<{}> = () => {
  return (
    <Container>
      <CarMetaForm />
      <InventorySummary />
    </Container>
  );
};

export default Sidebar;
