import React from "react";
import styled from "styled-components/macro";
import { Stat } from "../../declaration/inventory";
import { get } from "lodash";
import Typography from "../Common/Typograph/Typography";

interface Props {
  stat: Stat;
  maxAverageDaysInInventory: number;
}

const Container = styled.tr``;

const Td = styled.td`
 padding: 3px 0;
`;
const BackgroundBar = styled.div`
  position: relative;
  height: 12px;
  width: 110px;
  background-color: ${({ theme }) => theme.color.gray1};
  margin-right: 10px;
`;
const Label = styled.div`
  margin-right: 10px;
`;

const Bar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.secondary2};
`;

const AverageDaysInInventoryWrapper = styled.div`
  margin-right: 16px;
`;
const StatItem: React.FC<Props> = ({ stat, maxAverageDaysInInventory }) => {
  const averageDaysInInventory = get(stat, "average_days_in_inventory");
  const carsCount = get(stat, "cars_count");
  const valueDisplay = get(stat, "value_display");
  return (
    <Container>
      <Td>
        <Label>
          <Typography variant="body4">{valueDisplay}</Typography>
        </Label>
      </Td>
      <Td>
        <BackgroundBar>
          <Bar
            style={{
              width: `${
                averageDaysInInventory
                  ? (averageDaysInInventory / maxAverageDaysInInventory) * 100
                  : 0
              }%`,
            }}
          />
        </BackgroundBar>
      </Td>
      <Td>
        <AverageDaysInInventoryWrapper>
          <Typography variant="body4">
            {averageDaysInInventory ? `${averageDaysInInventory}일` : "-"}
          </Typography>
        </AverageDaysInInventoryWrapper>
      </Td>
      <Td>
        <Typography variant="body2">{carsCount}대</Typography>
      </Td>
    </Container>
  );
};

export default StatItem;
