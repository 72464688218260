import { all, put, take } from "redux-saga/effects";
import {
  AuthenticationActions,
  AuthenticationTypes
} from "../store/authentication";
import { createAsyncSaga } from "../cores/createAsyncSaga";
import { PayloadAction } from "typesafe-actions";
import { AxiosResponse } from "axios";
import { ToastActions, ToastMessageTypes } from "../store/toast";
import Cookies from "js-cookie";

export const tokenName = "csrftoken";
export default function*() {
  yield all([
    ...createAsyncSaga(AuthenticationActions),
    watchSetLogin(),
    watchSetLogout()
  ]);
}

function* watchSetLogin() {
  while (true) {
    const {
      payload: { data }
    }: PayloadAction<
      typeof AuthenticationTypes.setLogin,
      AxiosResponse<{ token: string; user: { is_staff: boolean } }>
    > = yield take(AuthenticationActions.setLogin.success);


    const cookieToken = Cookies.get(tokenName) || null;

    if (cookieToken) {
      yield put(AuthenticationActions.setToken(cookieToken));
      yield put(
        ToastActions.createToast({
          type: ToastMessageTypes.Success,
          message: "로그인 되었습니다."
        })
      );
    }
  }
}

function* watchSetLogout() {
  while (true) {
    yield take(AuthenticationTypes.setLogout);
  }
}
