import React, { FC, useRef } from "react";
import styles from "./Checkbox.module.scss";
import classNames from "classnames";

interface Props {
  className?: string;
  value: string;
  label?: string;
  checked: boolean;
  readOnly?: boolean;
  onClickCheckbox?: (value: string) => void;
}

const Checkbox: FC<Props & React.HTMLAttributes<HTMLInputElement>> = ({
  className,
  value,
  checked,
  onClickCheckbox,
  readOnly,
  label,
  ...otherProps
}) => {
  const handleChange = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (onClickCheckbox) {
      onClickCheckbox(value);
    }
  };
  return (
    <div
      className={classNames(styles.checkbox, className)}
      onClick={handleChange}
    >
      <label className={styles.wrapper}>
        <input
          className={styles.input}
          type="checkbox"
          checked={checked}
          readOnly={readOnly}
          {...otherProps}
        />
        <div className={classNames(styles.shape, checked && styles.isChecked)}>
          {checked && <img src="/check-icon.svg" alt="체크" />}
        </div>
        {label && <div className={styles.label}>{label}</div>}
      </label>
    </div>
  );
};

export default Checkbox;
