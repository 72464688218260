import produce from "immer";
import { Action, handleActions } from "redux-actions";
import { action } from "typesafe-actions";
import axios from "axios";
import createAsyncSagaAction from "../cores/createAsyncSagaAction";
import { getToken } from "../cores/getToken";

export enum AuthenticationTypes {
  setToken = "@authentication/setToken",
  setLogin = "@authentication/setLogin",
  setLogout = "@authentication/setLogout",
}

export interface AuthenticationState {
  token: string | null;
}

export const AuthenticationActions = {
  setToken: (token: string | null) =>
    action(AuthenticationTypes.setToken, token),
  setLogin: createAsyncSagaAction(
    AuthenticationTypes.setLogin,
    (data: { username: string; password: string }) =>
      axios.post(`${process.env["REACT_APP_DEALER_HOST"]}/login/`, {
        ...data,
        device_type: "pc",
      })
  ),
  setLogout: () => action(AuthenticationTypes.setLogout),
};

const initialState: AuthenticationState = {
  token: getToken(),
};

export default handleActions<AuthenticationState, any>(
  {
    [AuthenticationTypes.setToken]: (
      state,
      action: Action<AuthenticationState["token"]>
    ) => {
      return produce(state, (draft) => {
        draft.token = action.payload;
      });
    },
    [AuthenticationTypes.setLogout]: (
      state,
      action: Action<AuthenticationState["token"]>
    ) => {
      return produce(state, (draft) => {
        draft.token = null;
      });
    },
  },
  initialState
);
