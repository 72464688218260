import React, { FC } from 'react';
import styles from './Switch.module.scss';
import classNames from 'classnames';
import { MdClose, MdCheck } from 'react-icons/md';

interface Props {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const Switch: FC<Props> = ({ label, value, onChange }) => {
  return (
    <div className={classNames(styles.switch, value && styles.isActivated)}>
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={styles.body}
        onClick={() => {
          onChange(!value);
        }}
      >
        <div className={styles.shape}>
          <span className={styles.icon}>{value ? <MdCheck /> : <MdClose />}</span>
        </div>
      </div>
    </div>
  );
};

export default Switch;
