import React, { useEffect } from "react";
import styled from "styled-components/macro";
import Typography from "../Common/Typograph/Typography";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { InventoryActions } from "../../store/inventory";
import { map, chain } from "lodash";
import Stat from "./Stat";

interface Props {
  model: string | null;
}

const Container = styled.div`
  padding: 20px;
`;

const StatWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;
const StatsByField: React.FC<Props> = ({ model }) => {
  const { statsByFields } = useTypedSelector(
    ({ inventory: { statsByFields } }) => {
      return {
        statsByFields,
      };
    }
  );
  const maxAverageDaysInInventory = chain(statsByFields)
    .map("stats")
    .flatten()
    .maxBy("average_days_in_inventory")
    .get("average_days_in_inventory")
    .value();

  const dispatch = useDispatch();
  useEffect(() => {
    if (model) {
      dispatch(InventoryActions.getStatsByField(model));
    }
  }, [model]);

  return (
    <Container>
      <Typography variant="h3">재고기간 분석</Typography>
      <StatWrapper>
        {map(statsByFields, (statsByField) => (
          <Stat
            key={statsByField.field}
            statsByField={statsByField}
            maxAverageDaysInInventory={maxAverageDaysInInventory}
          />
        ))}
      </StatWrapper>
    </Container>
  );
};

export default StatsByField;
