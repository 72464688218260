import React, { FC } from 'react';
import styles from './CarMetaItem.module.scss';
import classNames from 'classnames';
import Ink from "react-ink";

interface Props {
  name: string;
  count: number;
  isSelected: boolean;
  onClick: () => void;
}

const CarMetaItem: FC<Props> = ({ name, count, isSelected, onClick }) => {
  const isDisabled = count <= 0;
  return (
    <div className={classNames(styles.carMetaItem, isSelected && styles.isSelected, isDisabled && styles.isDisabled)} onClick={onClick}>
      <div className={styles.radio} />
      <div className={styles.label}>{name}</div>
      <div className={styles.count}>{count}</div>
      <Ink />
    </div>
  );
};

export default CarMetaItem;
