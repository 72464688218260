import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  basicWidth: "1300px",
  color: {
    blue1: "#396eff",
    gray0: "#98a3ad",
    gray1: "#e9edf4",
    gray2: "#DADCE0",
    gray3: "#3C4043",
    gray4: "#869ab7",
    primary1: "#272e40",
    secondary1: "#869ab7",
    secondary2: "#afc2db",
  },
};

export { theme };
