import { all, delay, fork, put, select, take } from 'redux-saga/effects';
import { Toast, ToastActions, ToastMessageStatus, ToastMessageTypes, ToastTypes } from '../store/toast';
import { get, isError } from 'lodash';
import { AxiosResponse } from 'axios';

export default function* rootSaga() {
  yield all([watchToastSaga(), watchAsyncActions()]);
}

function* watchAsyncActions() {
  while (true) {
    try {
      const { payload } = yield take('*');

      if (isError(payload)) {
        const response: AxiosResponse<{ toast_message: string | null }> = get(payload, 'response');
        const toastMessage = get(response, 'data.toast_message');

        if (toastMessage) {
          yield put(ToastActions.createToast({ type: ToastMessageTypes.Error, message: toastMessage }));
        }
      }
    } catch (e) {}
  }
}

function* endToast(toast: Toast) {
  yield delay(2000);
  yield put(ToastActions.setToastStatus(toast.id, ToastMessageStatus.Hide));
  yield delay(2000);
  yield put(ToastActions.deleteToast(toast.id));
}

function* watchToastSaga() {
  while (true) {
    yield take(ToastTypes.createToast);
    const toast = yield select(state => state.toast.toasts[state.toast.toasts.length - 1]);
    yield fork(endToast, toast);
  }
}
