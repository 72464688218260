import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import get from "lodash/get";
import Checkbox from "../Common/Checkbox/Checkbox";
import { useDispatch } from "react-redux";
import { InventoryActions, InventoryTypes } from "../../store/inventory";
import OutsideClickHandler from "react-outside-click-handler";
import { ImSortAlphaAsc, ImSortAlphaDesc } from "react-icons/im";
import Ink from "react-ink";
import { Sort } from "../../declaration/inventory";
import { SequenceActions } from "../../store/sequence";
import Button from "../Common/Button/Button";
import size from "lodash/size";
import ClipLoader from "react-spinners/ClipLoader";
import Filters from "./Filters";

interface Props {
  model: string;
  fieldKey: string;
  onClose: () => void;
  filterEnabled: boolean;
}

const Container = styled.div<{ isDetail: boolean }>`
  min-width: ${({ isDetail }) => (isDetail ? "220px" : "180px")};
  background: white;
  position: absolute;
  top: 40px;
  left: 0;
  padding: 10px;
  box-shadow: 0 2px 6px 2px rgb(60 64 67 / 15%);
  border-radius: 4px;
  color: ${({ theme }) => theme.color.primary1};
  font-weight: normal;
  word-break: keep-all;
`;

const MenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding: 6px;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: rgba(233, 237, 244, 0.5);
  }
`;

const Border = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray0};
  opacity: 0.2;
  margin: 14px 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

const FilterList = styled.div`
  max-height: 150px;
  overflow: auto;
`;

const SpinnerWrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FieldMenu: React.FC<Props> = ({
  model,
  fieldKey,
  onClose,
  filterEnabled,
}) => {
  const isDetail = fieldKey === "detail_id";
  const { filter, selectedFilter, isLoading } = useTypedSelector(
    ({ inventory: { filter, selectedFilter }, loading: { asyncMap } }) => {
      return {
        selectedFilter,
        filter,
        isLoading: get(asyncMap, InventoryTypes.getFilter) > 0,
      };
    }
  );

  const dispatch = useDispatch();

  const filterFieldKeys = get(filter, [model, fieldKey]);
  const selectedFilterFieldKeys = get(selectedFilter, [model, fieldKey]);

  const [filterForm, setFilterForm] = useState<string[]>([]);
  useEffect(() => {
    if (selectedFilterFieldKeys) {
      setFilterForm(selectedFilterFieldKeys);
    }
  }, [selectedFilterFieldKeys]);

  useEffect(() => {
    if (!selectedFilterFieldKeys && filterFieldKeys) {
      setFilterForm(filterFieldKeys.map((filter) => filter.value));
    }
  }, [filter]);

  const onClickSort = (sort: Sort) => {
    dispatch(InventoryActions.setSelectedSort(model, fieldKey, sort));
    onClose();
  };

  const onClickCancel = () => {
    onClose();
  };

  const onClickConfirm = () => {
    dispatch(
      SequenceActions.createSequence([
        InventoryActions.setSelectedFilter(model, fieldKey, filterForm),
        () => {
          onClose();
        },
      ])
    );
  };

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <Container isDetail={isDetail}>
        <MenuItem onClick={() => onClickSort("asc")}>
          <ImSortAlphaAsc style={{ marginRight: 6 }} />
          오름차순 정렬
          <Ink />
        </MenuItem>

        <MenuItem onClick={() => onClickSort("desc")}>
          <ImSortAlphaDesc style={{ marginRight: 6 }} />
          내림차순 정렬
          <Ink />
        </MenuItem>
        {filterEnabled && (
          <>
            <Border />
            {isLoading ? (
              <SpinnerWrapper>
                <ClipLoader color="#396eff" size={20} />
              </SpinnerWrapper>
            ) : (
              <>
                <FilterList>
                  <Filters
                    fieldKey={fieldKey}
                    filterForm={filterForm}
                    setFilterForm={setFilterForm}
                    filterFieldKeys={filterFieldKeys}
                  />
                </FilterList>
                <Footer>
                  <Button
                    variant="text"
                    style={{ marginRight: 4 }}
                    onClick={onClickCancel}
                  >
                    취소
                  </Button>
                  <Button
                    variant="primary"
                    onClick={onClickConfirm}
                    disabled={size(filterForm) === 0}
                  >
                    확인
                  </Button>
                </Footer>
              </>
            )}
          </>
        )}
      </Container>
    </OutsideClickHandler>
  );
};

export default FieldMenu;
