import { combineReducers } from "redux";
import loading, { LoadingState } from "./loading";
import sequence, { SequenceState } from "./sequence";
import inventory, { InventoryState } from "./inventory";
import meta, { MetaState } from "./meta";
import toast, { ToastState } from "./toast";
import authentication, { AuthenticationState } from "./authentication";

export default combineReducers<Application>({
  inventory,
  loading,
  sequence,
  meta,
  toast,
  authentication,
});

export interface Application {
  toast: ToastState;
  inventory: InventoryState;
  loading: LoadingState;
  sequence: SequenceState;
  meta: MetaState;
  authentication: AuthenticationState;
}
