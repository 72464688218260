import produce from 'immer';
import { Reducer, handleActions, Action } from 'redux-actions';
import { action } from 'typesafe-actions';

let id = 0;

export enum ToastMessageTypes {
  Error,
  Success,
  Warning,
  Information
}

export enum ToastMessageStatus {
  Visible,
  Hide
}

export interface Toast {
  id: number;
  type: ToastMessageTypes;
  status: ToastMessageStatus;
  message: string;
}

export enum ToastTypes {
  createToast = '@toast/createToast',
  deleteToast = '@toast/deleteToast',
  setToastStatus = '@toast/setToastStatus'
}

export interface ToastState {
  toasts: Array<Toast>;
}

export const ToastActions = {
  createToast: (toast: Partial<Toast>) => action(ToastTypes.createToast, toast),
  deleteToast: (id: number) => action(ToastTypes.deleteToast, id),
  setToastStatus: (id: number, status: ToastMessageStatus) => action(ToastTypes.setToastStatus, { id, status })
};

const initialState: ToastState = {
  toasts: []
};

export default handleActions<ToastState, any>(
  {
    [ToastTypes.createToast]: (state, action: Action<Partial<Toast>>) => {
      const { type, message } = action.payload;
      if (type !== undefined && message !== undefined) {
        return produce(state, draft => {
          const toast: Toast = {
            id,
            type,
            status: ToastMessageStatus.Visible,
            message
          };

          id++;

          draft.toasts.push(toast);
        });
      }

      return state;
    },
    [ToastTypes.setToastStatus]: (state, action: Action<{ id: number; status: ToastMessageStatus }>) => {
      const { id, status } = action.payload;
      const findToastIndex = state.toasts.findIndex(toast => toast.id === id);
      if (findToastIndex !== -1) {
        return produce(state, draft => {
          draft.toasts[findToastIndex].status = status;
        });
      }

      return state;
    },
    [ToastTypes.deleteToast]: (state, action: Action<number>) => {
      const findToastIndex = state.toasts.findIndex(toast => toast.id === action.payload);
      if (findToastIndex !== -1) {
        return produce(state, draft => {
          draft.toasts.splice(findToastIndex, 1);
        });
      }

      return state;
    }
  },
  initialState
);
