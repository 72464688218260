import React, { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import Ink from "react-ink";

type Variant = "primary" | "text" | "secondary" | "thirdly";
interface Props {
  variant: Variant;
  className?: string;
  disabled?: boolean;
}
const Button: React.FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  variant,
  disabled = false,
  children,
  className,
  ...others
}) => {
  return (
    <button
      className={classNames(styles.button, styles[variant], className, {
        [styles.disabled]: disabled,
      })}
      {...others}
      disabled={disabled}
    >
      {children}
      <Ink />
    </button>
  );
};

export default Button;
