import React from "react"
import styles from "./Typography.module.scss"
import classNames from "classnames"

type Variant = "h1" | "h2" | "h3" | "subTitle" | "body1" | "body2" | "body3" | "body4" | 'label'
interface Props {
  children: React.ReactNode
  variant: Variant
  className?: string
}
const Typography: React.FC<Props> = ({ children, variant, className }) => {
  return (
    <div
      className={classNames(
        styles.typography,
        {
          [styles.h1]: variant === "h1",
          [styles.h2]: variant === "h2",
          [styles.h3]: variant === "h3",
          [styles.body1]: variant === "body1",
          [styles.body2]: variant === "body2",
          [styles.body3]: variant === "body3",
          [styles.body4]: variant === "body4",
          [styles.subTitle]: variant === "subTitle",
          [styles.label]: variant === "label",
        },
        className
      )}
    >
      {children}
    </div>
  )
}

export default Typography
