import React from "react";
import styled from "styled-components/macro";
import Typography from "./Common/Typograph/Typography";
import { round } from "lodash";
interface Props {
  label: string;
  count: number | undefined;
  rating: number | undefined;
}
const Container = styled.div`
  display: flex;
  align-items: center;
  &:not(&:last-child) {
    margin-bottom: 8px;
  }
`;
const BackgroundBar = styled.div`
  position: relative;
  height: 12px;
  width: 115px;
  background-color: ${({ theme }) => theme.color.gray1};
  margin-right: 10px;
`;
const Bar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.blue1};
`;

const Label = styled.div`
  min-width: 55px;
`;

const Rating = styled.div`
  font-weight: bold;
  min-width: 32px;
  margin-right: 10px;
  color: ${({ theme }) => theme.color.blue1};
`;
const InventorySummaryBar: React.FC<Props> = ({ label, count, rating }) => {
  return (
    <Container>
      <Label>
        <Typography variant="body4">{label}</Typography>
      </Label>
      <BackgroundBar>
        <Bar
          style={{
            width: `${rating ? rating * 100 : 0}%`,
          }}
        />
      </BackgroundBar>
      <Rating>{rating ? round(rating * 100) : 0}%</Rating>
      <Typography variant="body2">{count}대</Typography>
    </Container>
  );
};

export default InventorySummaryBar;
