import React, { Dispatch, SetStateAction, useEffect } from "react";
import Checkbox from "../Common/Checkbox/Checkbox";
import includes from "lodash/includes";
import styled from "styled-components";
import produce from "immer";
import { Filter as IFilter } from "../../declaration/inventory";
import { map } from "lodash";
import size from "lodash/size";
import Typography from "../Common/Typograph/Typography";

interface Props {
  fieldKey: string;
  filterFieldKeys: IFilter[];
  filterForm: string[];
  setFilterForm: Dispatch<SetStateAction<string[]>>;
}
const CheckItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 3px 0;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: rgba(233, 237, 244, 0.5);
  }
`;


const Count = styled.div`
  margin-left: 8px;
`
export const mileageFilters = [
  {
    label: "0 ~ 3만 km",
    filter: "0~3",
    min_mileage: 0,
    max_mileage: 30000,
  },
  {
    label: "3 ~ 5만 km",
    filter: "3~5",
    min_mileage: 30000,
    max_mileage: 50000,
  },
  {
    label: "5 ~ 10만 km",
    filter: "5~10",
    min_mileage: 50000,
    max_mileage: 100000,
  },
  {
    label: "10 ~ 15만 km",
    filter: "10~15",
    min_mileage: 100000,
    max_mileage: 150000,
  },
  {
    label: "15 ~ 20만 km",
    filter: "15~20",
    min_mileage: 150000,
    max_mileage: 200000,
  },
  {
    label: "20만 km 이상",
    filter: "20~",
    min_mileage: 200000,
    max_mileage: null,
  },
];
const Filters: React.FC<Props> = ({
  fieldKey,
  filterForm,
  setFilterForm,
  filterFieldKeys,
}) => {
  const isMileage = fieldKey === "mileage";
  const getIsAllSelected = () => {
    if (isMileage) {
      return (
        size(filterForm) ===
        size(mileageFilters.map((mileageFilter) => mileageFilter.filter))
      );
    } else {
      return size(filterForm) === size(filterFieldKeys);
    }
  };

  useEffect(() => {
    if (isMileage) {
      setFilterForm(
        mileageFilters.map((mileageFilter) => mileageFilter.filter)
      );
    }
  }, []);

  const onClickCheckbox = (value: string) => {
    const deleteFilterIndex = filterForm.indexOf(value);
    if (deleteFilterIndex !== -1) {
      setFilterForm(
        produce(filterForm, (draft) => {
          draft.splice(deleteFilterIndex, 1);
        })
      );
    } else {
      setFilterForm([...filterForm, value]);
    }
  };

  const onClickSelectAll = () => {
    if (getIsAllSelected()) {
      setFilterForm([]);
    } else {
      setFilterForm(
        isMileage
          ? mileageFilters.map((mileageFilter) => mileageFilter.filter)
          : filterFieldKeys.map((filter) => filter.value)
      );
    }
  };

  const renderFilters = () => {
    let filters = null;
    switch (fieldKey) {
      case "mileage": {
        filters = (
          <>
            {map(mileageFilters, (mileageFilter) => (
              <CheckItem>
                <Checkbox
                  value={mileageFilter.filter}
                  checked={includes(filterForm, mileageFilter.filter)}
                  label={mileageFilter.label}
                  onClickCheckbox={onClickCheckbox}
                />
              </CheckItem>
            ))}
          </>
        );
        break;
      }
      default: {
        filters = (
          <>
            {map(filterFieldKeys, (filter) => (
              <CheckItem>
                <Checkbox
                  value={filter.value}
                  checked={includes(filterForm, filter.value)}
                  label={filter.value_display}
                  onClickCheckbox={onClickCheckbox}
                />
                <Count>
                  <Typography variant="body2">{filter.count}대</Typography>
                </Count>
              </CheckItem>
            ))}
          </>
        );
      }
    }
    return filters;
  };
  return (
    <>
      <CheckItem>
        <Checkbox
          value={""}
          checked={getIsAllSelected()}
          label="모두선택"
          onClickCheckbox={onClickSelectAll}
        />
      </CheckItem>
      {renderFilters()}
    </>
  );
};

export default Filters;
