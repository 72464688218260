import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./CarMetaForm.module.scss";
import { Scrollbars } from "react-custom-scrollbars";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MetaActions, MetaTypes } from "../../store/meta";
import { get, map, chain, findIndex, some } from "lodash";
import CarMetaItem from "./CarMetaItem/CarMetaItem";
import Switch from "../Common/Switch/Switch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import ClipLoader from "react-spinners/ClipLoader";

interface Props {}

const orders = ["brand", "model_group", "model", "grade"];

const CarMetaForm: FC<Props & RouteComponentProps> = ({
  location: { key, search },
  history,
  match: { params },
}) => {
  const scrollbarRef = useRef<Scrollbars | null>(null);
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const category = get(params, "category");
  const selectedBrand = query.get("brand");
  const selectedModelGroup = query.get("model_group");
  const [isExceptZero, setIsExceptZero] = useState(true);

  const maxApprovedAt = query.get("max_approved_at");
  const minApprovedAt = query.get("min_approved_at");

  const hasBrand = selectedBrand !== null;
  const hasModelGroup = selectedModelGroup !== null;

  const {
    brands,
    modelGroup,
    model,
    grade,
    isBrandLoading,
    isModelGroupLoading,
    isModelLoading,
  } = useTypedSelector(
    ({
      loading: { asyncMap },
      meta: { brands, modelGroup, model, grade },
    }) => ({
      brands,
      modelGroup,
      model,
      grade,
      isBrandLoading: get(asyncMap, MetaTypes.getBrands) > 0,
      isModelGroupLoading: get(asyncMap, MetaTypes.getModelGroups) > 0,
      isModelLoading: get(asyncMap, MetaTypes.getModels) > 0,
    })
  );

  const isMetaLoading = some([
    isBrandLoading,
    isModelGroupLoading,
    isModelLoading,
  ]);
  useEffect(() => {
    if (!scrollbarRef.current) {
      return;
    }

    scrollbarRef.current.scrollTop(0);
  }, [modelGroup, model, grade]);

  useEffect(() => {
    dispatch(MetaActions.getBrands(query));

    return () => {
      dispatch(MetaActions.truncateBrands());
    };
  }, [category, maxApprovedAt, minApprovedAt]);

  useEffect(() => {
    if (!selectedBrand) {
      return;
    }

    dispatch(MetaActions.getModelGroups(selectedBrand, query));

    return () => {
      dispatch(MetaActions.truncateModelGroups());
    };
  }, [selectedBrand, maxApprovedAt, minApprovedAt]);

  useEffect(() => {
    if (!selectedModelGroup) {
      return;
    }

    dispatch(MetaActions.getModels(selectedModelGroup, query));

    return () => {
      dispatch(MetaActions.truncateModels());
    };
  }, [selectedModelGroup, maxApprovedAt, minApprovedAt, selectedBrand]);

  const renderCarMetaItem = useCallback(
    (type: string, carMeta?: any, isMultiple: boolean = false) => {
      if (!carMeta) {
        return null;
      }

      const name = get(carMeta, "name");
      const count = get(carMeta, "count");
      const hashId = get(carMeta, "hash_id");
      const isSelected = isMultiple
        ? query.getAll(type).indexOf(hashId) !== -1
        : hashId === query.get(type);

      if (isExceptZero && count <= 0) {
        return null;
      }

      return (
        <CarMetaItem
          key={hashId}
          name={name}
          count={count}
          isSelected={isSelected}
          onClick={() => {
            if (isSelected) {
              if (isMultiple) {
                const values = query.getAll(type);
                const selectedIndex = values.indexOf(hashId);

                if (selectedIndex !== -1) {
                  values.splice(selectedIndex, 1);

                  query.delete(type);

                  for (let i = 0; i < values.length; i++) {
                    query.append(type, values[i]);
                  }
                }
              } else {
                query.delete(type);

                const findOrderIndex = findIndex(
                  orders,
                  (order) => order === type
                );
                if (findOrderIndex !== -1) {
                  chain(orders)
                    .slice(findOrderIndex + 1, chain(orders).size().value())
                    .forEach((order) => {
                      query.delete(order);
                    })
                    .value();
                }

                if (type === "model") {
                  selectedModelGroup &&
                  dispatch(MetaActions.getModels(selectedModelGroup, query));
                }
              }
            } else {
              if (isMultiple) {
                query.append(type, hashId);
              } else {
                query.set(type, hashId);
              }
            }

            history.push(`?${query.toString()}`);
          }}
        />
      );
    },
    [key, isExceptZero]
  );

  return (
    <div className={styles.carMetaForm}>
      <div className={styles.header}>
        <span>차종 검색</span>
        <Switch
          label="0대 제외"
          value={isExceptZero}
          onChange={(value: boolean) => {
            setIsExceptZero(value);
          }}
        />
      </div>
      <div className={styles.body}>
        {isMetaLoading ? (
          <div className={styles.spinnerWrapper}>
            <ClipLoader size={24} color="#396eff"/>
          </div>
        ) : (
          <>
            {hasBrand && (
              <div className={styles.selectedBrand}>
                {renderCarMetaItem(
                  "brand",
                  chain(brands)
                    .find((brand) => get(brand, "hash_id") === selectedBrand)
                    .value()
                )}
              </div>
            )}
            <Scrollbars
              ref={(ref) => {
                scrollbarRef.current = ref;
              }}
            >
              {!hasBrand && (
                <div className={styles.brands}>
                  {map(brands, (brand) => renderCarMetaItem("brand", brand))}
                </div>
              )}
              {hasBrand && (
                <div className={styles.modelGroups}>
                  {hasModelGroup ? (
                    <div className={styles.selectedModelGroup}>
                      {renderCarMetaItem(
                        "model_group",
                        chain(modelGroup)
                          .get("model_groups")
                          .find(
                            (modelGroup) =>
                              get(modelGroup, "hash_id") === selectedModelGroup
                          )
                          .value()
                      )}
                    </div>
                  ) : (
                    selectedBrand &&
                    chain(modelGroup)
                      .get("model_groups")
                      .map((modelGroup) =>
                        renderCarMetaItem("model_group", modelGroup)
                      )
                      .value()
                  )}
                  <div className={styles.models}>
                    {hasModelGroup &&
                      chain(model)
                        .get("models")
                        .map((model) => renderCarMetaItem("model", model))
                        .value()}
                  </div>
                </div>
              )}
            </Scrollbars>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(CarMetaForm);
