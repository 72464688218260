import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import FieldWrapper from "./FieldWrapper";
import { useDispatch } from "react-redux";
import { InventoryActions, InventoryTypes } from "../../store/inventory";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { toLocaleString } from "../../cores/toLocaleString";
import { DateTime } from "luxon";
import { filter, get, includes, maxBy, minBy, size, toString } from "lodash";
import forEach from "lodash/forEach";
import Typography from "../Common/Typograph/Typography";
import { SequenceActions } from "../../store/sequence";
import ClipLoader from "react-spinners/ClipLoader";
import { mileageFilters } from "./Filters";

interface Props {
  model: string;
}

const Container = styled.div`
  padding: 20px;
`;

const Content = styled.div`
  position: relative;
`;

const TableWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  height: 400px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.color.secondary2};
`;

const InventoryTable = styled.table`
  width: 100%;
  position: relative;
  border-collapse: collapse;
`;

const Tbody = styled.tbody`
  position: relative;
`;

const Row = styled.tr`
  &:not(&:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.secondary2};
  }
  &:nth-child(2n) {
    background: #f7f8fb;
  }
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: white;
  box-shadow: 0 1px 4px 0 rgba(65, 77, 107, 0.3);
`;

const Td = styled.td`
  line-height: 1.4;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  word-break: keep-all;
  &:not(&:last-child) {
    border-right: 1px solid ${({ theme }) => theme.color.secondary2};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EndOfTable = styled.div`
  padding: 20px;
  text-align: center;
`;

let isInfiniteScrollActive = false;
const InventoryRecords: React.FC<Props> = ({ model }) => {
  const dispatch = useDispatch();
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);

  const {
    inventoryRecords,
    inventoryRecordsMeta,
    selectedFilter,
    selectedSort,
    isLoading,
  } = useTypedSelector(
    ({
      inventory: {
        inventoryRecords,
        inventoryRecordsMeta,
        selectedFilter,
        selectedSort,
      },
      loading: { asyncMap },
    }) => {
      return {
        inventoryRecords,
        inventoryRecordsMeta,
        selectedFilter,
        selectedSort,
        isLoading: get(asyncMap, InventoryTypes.getInventoryRecords) > 0,
      };
    }
  );

  const count = get(inventoryRecordsMeta, "count");
  const params = new URLSearchParams();
  params.set("envelope", "true");
  params.set("model", model);
  params.set("page", toString(page));
  params.delete("brand");
  params.delete("model_group");
  forEach(selectedFilter[model], (filters, key) => {
    if (key === "mileage") {
      if (size(filters) !== size(mileageFilters)) {
        const selectedMileageFilters = filter(mileageFilters, (filter) => {
          return includes(filters, filter.filter);
        });
        const selectedMinMileage = minBy(selectedMileageFilters, "min_mileage");
        const selectedMaxMileage = maxBy(selectedMileageFilters, "max_mileage");
        if (selectedMinMileage) {
          params.append(
            "min_mileage",
            toString(selectedMinMileage.min_mileage)
          );
        }

        if (selectedMaxMileage) {
          params.append(
            "max_mileage",
            toString(selectedMaxMileage.max_mileage)
          );
        }
      }
    } else {
      forEach(filters, (filter) => {
        params.append(key, filter);
      });
    }
  });

  const sortKey = get(selectedSort, [model, "key"]);
  const sort = get(selectedSort, [model, "sort"]);

  if (sortKey) {
    params.set("order_by", sortKey);
  }
  if (sort) {
    params.set("ascending", sort === "asc" ? "true" : "false");
  }

  const onScroll = () => {
    if (tableWrapperRef.current) {
      const scrollTop = tableWrapperRef.current.scrollTop;
      const scrollHeight = tableWrapperRef.current.scrollHeight;
      const { height } = tableWrapperRef.current.getBoundingClientRect();
      const next = get(inventoryRecordsMeta, "next");
      if (
        scrollHeight - height - scrollTop < 300 &&
        !isInfiniteScrollActive &&
        next
      ) {
        isInfiniteScrollActive = true;
        setPage((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    if (page === 1) {
      return;
    }

    dispatch(
      SequenceActions.createSequence([
        InventoryActions.getInventoryRecords(params),
        () => {
          isInfiniteScrollActive = false;
        },
      ])
    );
  }, [page]);

  useEffect(() => {
    setPage(1);
    params.set("page", "1");
    dispatch(
      SequenceActions.createSequence([
        InventoryActions.truncateInventoryRecords(),
        InventoryActions.getInventoryRecords(params),
        () => {
          isInfiniteScrollActive = false;
        },
      ])
    );
  }, [selectedFilter, selectedSort, model]);

  console.log(size(inventoryRecords));

  return (
    <Container>
      <Header>
        <Typography variant="h3">판매 데이터</Typography>
        {count !== 0 ? (
          <Typography variant="h2">{count} 대</Typography>
        ) : (
          <Typography variant="h2"> - 대</Typography>
        )}
      </Header>
      <Content>
        <TableWrapper ref={tableWrapperRef} onScroll={onScroll}>
          <InventoryTable>
            <FieldWrapper model={model} />
            <Tbody>
              {inventoryRecords &&
                inventoryRecords.map((inventoryRecord) => {
                  const firstFoundAt = get(inventoryRecord, "first_found_at");
                  const displayFirstFoundAt = firstFoundAt
                    ? DateTime.fromFormat(
                        firstFoundAt,
                        "yyyy-MM-dd hh:mm:ss"
                      ).toFormat("yyyy-MM-dd")
                    : "-";
                  const soldAt = get(inventoryRecord, "sold_at");
                  const displaySoldAt = soldAt
                    ? DateTime.fromFormat(
                        soldAt,
                        "yyyy-MM-dd hh:mm:ss"
                      ).toFormat("yyyy-MM-dd")
                    : "-";

                  return (
                    <Row key={inventoryRecord.car_number}>
                      <Td style={{ width: "8%" }}>
                        {inventoryRecord?.car_number}
                      </Td>
                      <Td style={{ width: "6%" }}>
                        {inventoryRecord?.days_in_inventory
                          ? `${inventoryRecord?.days_in_inventory}일`
                          : "-"}
                      </Td>
                      <Td style={{ width: "16%", textAlign: 'left' }}>
                        {inventoryRecord?.detail_display}
                      </Td>
                      <Td style={{ width: "7%" }}>{inventoryRecord?.color}</Td>
                      <Td style={{ width: "8%" }}>
                        {inventoryRecord?.year
                          ? `${inventoryRecord?.year}년형`
                          : "-"}
                      </Td>
                      <Td style={{ width: "9%" }}>
                        {toLocaleString(inventoryRecord?.mileage)}km
                      </Td>
                      <Td style={{ width: "8%" }}>{inventoryRecord?.fuel}</Td>
                      <Td style={{ width: "10%" }}>
                        {inventoryRecord?.location_display}
                      </Td>
                      <Td style={{ width: "8%" }}>
                        {toLocaleString(inventoryRecord?.price)}만원
                      </Td>
                      <Td style={{ width: "10%" }}>{displayFirstFoundAt}</Td>
                      <Td style={{ width: "10%" }}>{displaySoldAt}</Td>
                    </Row>
                  );
                })}
              {size(inventoryRecords) === count && (
                <Row>
                  <Td colSpan={11}>
                    <Typography variant="body2">데이터 끝</Typography>
                  </Td>
                </Row>
              )}
            </Tbody>
          </InventoryTable>
        </TableWrapper>
        {isLoading && (
          <SpinnerWrapper>
            <ClipLoader color="#396eff" size={18} />
          </SpinnerWrapper>
        )}
      </Content>
    </Container>
  );
};

export default InventoryRecords;
