import produce from "immer";
import { handleActions } from "redux-actions";
import axios, { AxiosResponse } from "axios";
import { createAsyncSagaReducerMap } from "../cores/createAsyncSagaReducerMap";
import { action, PayloadAction } from "typesafe-actions";
import { chain, get, set } from "lodash";
import createAsyncSagaAction from "../cores/createAsyncSagaAction";
import {
  Brand,
  GradeWrapper,
  ModelGroupWrapper,
  ModelWrapper,
} from "../declaration/meta";

export enum MetaTypes {
  getGrades = "@meta/getGrades",
  getBrands = "@meta/getBrand",
  getModelGroups = "@meta/getModelGroups",
  getModels = "@meta/getModels",
  truncateBrands = "@meta/truncateBrands",
  truncateModelGroups = "@meta/truncateModelGroups",
  truncateModels = "@meta/truncateModels",
  truncateGrades = "@meta/truncateGrades",
}

export interface MetaState {
  brands: Array<Brand>;
  modelGroup: ModelGroupWrapper | null;
  model: ModelWrapper | null;
  grade: GradeWrapper | null;
}

export const MetaActions = {
  getBrands: createAsyncSagaAction(
    MetaTypes.getBrands,
    (params?: URLSearchParams) => axios.get(`/car_meta/brands/`, { params })
  ),
  getModelGroups: createAsyncSagaAction(
    MetaTypes.getModelGroups,
    (hashId: string, params?: URLSearchParams) =>
      axios.get(`/car_meta/brands/${hashId}/`, { params })
  ),
  getModels: createAsyncSagaAction(
    MetaTypes.getModels,
    (hashId: string, params?: URLSearchParams) =>
      axios.get(`/car_meta/model_groups/${hashId}/`, { params })
  ),
  getGrades: createAsyncSagaAction(
    MetaTypes.getGrades,
    (hashId: string, params?: URLSearchParams) =>
      axios.get(`/car_meta/models/${hashId}/`, { params })
  ),
  truncateBrands: () => action(MetaTypes.truncateBrands),
  truncateModelGroups: () => action(MetaTypes.truncateModelGroups),
  truncateModels: () => action(MetaTypes.truncateModels),
  truncateGrades: () => action(MetaTypes.truncateGrades),
};

const initialState: MetaState = {
  brands: [],
  modelGroup: null,
  model: null,
  grade: null,
};

export default handleActions<MetaState, any>(
  {
    [MetaTypes.truncateBrands]: (state, action) => {
      return produce(state, (draft) => {
        draft.brands = [];
      });
    },
    [MetaTypes.truncateModelGroups]: (state, action) => {
      return produce(state, (draft) => {
        draft.modelGroup = null;
      });
    },
    [MetaTypes.truncateModels]: (state, action) => {
      return produce(state, (draft) => {
        draft.model = null;
      });
    },
    [MetaTypes.truncateGrades]: (state, action) => {
      return produce(state, (draft) => {
        draft.grade = null;
      });
    },
    ...createAsyncSagaReducerMap(MetaTypes.getBrands, {
      onSuccess: (
        state,
        action: PayloadAction<any, AxiosResponse<Array<Brand>>>
      ) => {
        return produce(state, (draft) => {
          draft.brands = action.payload.data;
        });
      },
    }),
    ...createAsyncSagaReducerMap(MetaTypes.getModelGroups, {
      onSuccess: (
        state,
        { payload }: PayloadAction<any, AxiosResponse<ModelGroupWrapper>>
      ) => {
        return produce(state, (draft) => {
          const data = get(payload, "data");
          const count = get(data, "count");
          const hashId = get(data, "hash_id");
          const findBrandIndex = chain(state.brands)
            .findIndex((brand) => get(brand, "hash_id") === hashId)
            .value();

          draft.modelGroup = data;

          if (findBrandIndex !== -1) {
            draft.brands[findBrandIndex].count = count;
          }
        });
      },
    }),
    ...createAsyncSagaReducerMap(MetaTypes.getModels, {
      onSuccess: (
        state,
        { payload }: PayloadAction<any, AxiosResponse<ModelWrapper>>
      ) => {
        return produce(state, (draft) => {
          const data = get(payload, "data");
          const count = get(data, "count");
          const hashId = get(data, "hash_id");
          const findModelGroupIndex = chain(state.modelGroup)
            .get("model_groups")
            .findIndex((modelGroup) => get(modelGroup, "hash_id") === hashId)
            .value();

          draft.model = data;

          if (draft.modelGroup && findModelGroupIndex !== -1) {
            set(
              draft.modelGroup,
              ["model_groups", findModelGroupIndex, "count"],
              count
            );
          }
        });
      },
    }),
    ...createAsyncSagaReducerMap(MetaTypes.getGrades, {
      onSuccess: (
        state,
        { payload }: PayloadAction<any, AxiosResponse<GradeWrapper>>
      ) => {
        return produce(state, (draft) => {
          const data = get(payload, "data");
          const count = get(data, "count");
          const hashId = get(data, "hash_id");
          const findModelIndex = chain(state.model)
            .get("models")
            .findIndex((model) => get(model, "hash_id") === hashId)
            .value();

          draft.grade = data;

          if (draft.model && findModelIndex !== -1) {
            set(draft.model, ["models", findModelIndex, "count"], count);
          }
        });
      },
    }),
  },
  initialState
);
