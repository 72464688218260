import React, { useEffect, useState } from "react";
import styled from "styled-components";
import map from "lodash/map";
import Field from "./Field";
import { InventoryActions } from "../../store/inventory";
import { useDispatch } from "react-redux";

interface Props {
  model: string;
}

export interface FieldConfig {
  fieldKey: string;
  label: string;
  filterEnabled: boolean;
  width: string;
}

export interface SelectedFilter {
  color: string[];
}

const Thead = styled.thead`
  color: white;
  font-weight: bold;
`;

export const fieldsConfig: FieldConfig[] = [
  {
    fieldKey: "car_number",
    label: "차 번호",
    filterEnabled: false,
    width: '8%'
  },
  {
    fieldKey: "days_in_inventory",
    label: "재고기간",
    filterEnabled: false,
    width: '6%'
  },
  {
    fieldKey: "detail_id",
    label: "등급",
    filterEnabled: true,
    width: '16%'
  },
  {
    fieldKey: "color",
    label: "색상",
    filterEnabled: true,
    width: '7%'
  },
  {
    fieldKey: "year",
    label: "연식",
    filterEnabled: true,
    width: '8%'
  },
  {
    fieldKey: "mileage",
    label: "주행거리",
    filterEnabled: true,
    width: '9%'
  },
  {
    fieldKey: "fuel",
    label: "연료",
    filterEnabled: true,
    width: '8%'
  },
  {
    fieldKey: "location_second_part_id",
    label: "지역",
    filterEnabled: true,
    width: '10%'
  },
  {
    fieldKey: "price",
    label: "최종광고가",
    filterEnabled: false,
    width: '8%'
  },
  {
    fieldKey: "first_found_at",
    label: "광고 등록",
    filterEnabled: false,
    width: '10%'
  },
  {
    fieldKey: "sold_at",
    label: "광고 삭제",
    filterEnabled: false,
    width: '10%'
  },
];

const FieldWrapper: React.FC<Props> = ({ model }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(InventoryActions.truncateInventoryRecords());
    };
  }, []);

  return (
    <>
      <Thead>
        <tr>
          {map(fieldsConfig, (config) => (
            <Field config={config} model={model} />
          ))}
        </tr>
      </Thead>
    </>
  );
};

export default FieldWrapper;
