import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypedSelector";
import Login from "../pages/Login/Login";
import Home from "../pages/Home";
import ToastMessage from "./ToastMessage/ToastMessage";

const App: React.FC<{}> = () => {
  const history = useHistory();
  const { token } = useTypedSelector(({ authentication }) => authentication);

  useEffect(() => {
    if (!token) {
      history.replace({ pathname: "/login" });
    } else {
      history.replace({ pathname: "/" });
    }
  }, [token]);

  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Home} />
      </Switch>
      <ToastMessage />
    </>
  );
};

export default App;
