import React from "react";
import Header from "../components/Common/Header";
import styled from "styled-components/macro";
import Sidebar from "../components/Sidebar";
import InventoryRecords from "../components/InventoryRecords";
import StatsByField from "src/components/StatsByField/StatsByField";
import { useLocation } from "react-router-dom";
import Typography from "../components/Common/Typograph/Typography";

const Container = styled.div``;

const Body = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
`;

const Content = styled.div`
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.color.gray1};
`;

const Border = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray1};
`;

const EmptyView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
`;
function Home() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const model = params.get("model");

  return (
    <Container>
      <Header />
      <Body>
        <Sidebar />
        <Content>
          {model ? (
            <>
              <StatsByField model={model} />
              <Border />
              <InventoryRecords model={model} />
            </>
          ) : (
            <EmptyView>
              <Typography variant="h2">차량 모델을 선택해 주세요.</Typography>
            </EmptyView>
          )}
        </Content>
      </Body>
    </Container>
  );
}

export default Home;
