import React, { useEffect } from "react";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { InventoryActions } from "../store/inventory";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import styled from "styled-components/macro";
import Typography from "./Common/Typograph/Typography";
import InventorySummaryBar from "./InventorySummaryBar";
import { DateTime } from "luxon";

const Container = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.color.gray1};
`;

const Row = styled.div`
  display: flex;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:first-child {
    margin-right: 32px;
  }
`;

const AverageDaysInInventoryWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 8px;
`;

const AverageDaysInInventory = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

const MedianDaysInInventoryWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 8px;
  color: ${({ theme }) => theme.color.gray4};
`;

const MedianDaysInInventory = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const MedianDaysUnit = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Unit = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Border = styled.div`
  margin: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray1};
`;

const InventorySummaryBarWrapper = styled.div`
  margin-top: 16px;
`;

const DateWrapper = styled.div`
  margin-top: 20px;
`;

const InventorySummary: React.FC<{}> = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const model = params.get("model");

  const { summary } = useTypedSelector(({ inventory: { summary } }) => {
    return {
      summary,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (model) {
      dispatch(InventoryActions.getInventorySummary(model));
    }
  }, [model]);

  if (!model) {
    return null;
  }

  const averageDaysInInventory = get(summary, "average_days_in_inventory");
  const medianDaysInInventory = get(summary, "median_days_in_inventory");
  const soldIn7DaysCount = get(summary, "sold_in_7_days_count");
  const soldIn7DaysRate = get(summary, "sold_in_7_days_rate");
  const soldIn14DaysCount = get(summary, "sold_in_14_days_count");
  const SoldIn14DaysRate = get(summary, "sold_in_14_days_rate");
  const soldIn30DaysCount = get(summary, "sold_in_30_days_count");
  const soldIn30DaysRate = get(summary, "sold_in_30_days_rate");
  const startDate = get(summary, "start_date");
  const endDate = get(summary, "end_date");
  return (
    <>
      <Container>
        <Row>
          <Column>
            <Typography variant="body4">재고기간 평균</Typography>
            {averageDaysInInventory && (
              <AverageDaysInInventoryWrapper>
                <AverageDaysInInventory>
                  {averageDaysInInventory}
                </AverageDaysInInventory>
                <Unit>일</Unit>
              </AverageDaysInInventoryWrapper>
            )}
          </Column>
          <Column>
            <Typography variant="body4">중간값</Typography>
            {averageDaysInInventory && (
              <MedianDaysInInventoryWrapper>
                <MedianDaysInInventory>
                  {medianDaysInInventory}
                </MedianDaysInInventory>
                <MedianDaysUnit>일</MedianDaysUnit>
              </MedianDaysInInventoryWrapper>
            )}
          </Column>
        </Row>
        <Border />
        <Typography variant="body4">판매확률</Typography>
        <InventorySummaryBarWrapper>
          <InventorySummaryBar
            label="7일 내"
            count={soldIn7DaysCount}
            rating={soldIn7DaysRate}
          />
          <InventorySummaryBar
            label="14일 내"
            count={soldIn14DaysCount}
            rating={SoldIn14DaysRate}
          />
          <InventorySummaryBar
            label="30일 내"
            count={soldIn30DaysCount}
            rating={soldIn30DaysRate}
          />
        </InventorySummaryBarWrapper>
      </Container>
      <DateWrapper>
        <Typography variant="body2">
          *데이터 기준:
          {startDate
            ? DateTime.fromFormat(startDate, "yyyy-MM-dd").toFormat(
                "yyyy년 MM월 dd일"
              )
            : ""}
          &nbsp; - &nbsp;
          {endDate
            ? DateTime.fromFormat(endDate, "yyyy-MM-dd").toFormat(
                "yyyy년 MM월 dd일"
              )
            : ""}
        </Typography>
      </DateWrapper>
    </>
  );
};

export default InventorySummary;
