import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { AuthenticationActions } from "../../store/authentication";
const Container = styled.div`
  height: 60px;
  background-color: ${({ theme }) => theme.color.primary1};
`;

const Content = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 140px;
  cursor: pointer;
`;

const Logout = styled.span`
  color: white;
  cursor: pointer;
  border: 1px solid white;
  padding: 6px 12px;
`;
const Header: React.FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickLogo = () => {
    history.push("/");
  };

  const onClickLogout = () => {
    dispatch(AuthenticationActions.setLogout());
  };
  return (
    <Container>
      <Content>
        <Logo src="/logo.png" alt="로고" onClick={onClickLogo} />
        <Logout onClick={onClickLogout}>로그아웃</Logout>
      </Content>
    </Container>
  );
};

export default Header;
