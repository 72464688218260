import React from "react";
import styles from "./Stat.module.scss";
import { StatsByField } from "../../declaration/inventory";
import { get, map } from "lodash";
import Typography from "../Common/Typograph/Typography";
import styled from "styled-components/macro";
import StatItem from "./StatItem";
import Scrollbars from "react-custom-scrollbars";
interface Props {
  statsByField: StatsByField;
  maxAverageDaysInInventory: number;
}

const Container = styled.div`
  padding: 14px;
  border: 1px solid ${({ theme }) => theme.color.gray1};
  margin-top: 10px;
  margin-right: 10px;
`;

const Border = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray1};
`;

const StatItemTableWrapper = styled.div`
  height: 195px;
  overflow: auto;
`;
const StatItemTable = styled.table`
  padding-top: 12px;
`;

const FieldDisplayWrapper = styled.div`
  margin-bottom: 8px;
`;

const Stat: React.FC<Props> = ({ statsByField, maxAverageDaysInInventory }) => {
  const fieldDisplay = get(statsByField, "field_display");
  const stats = get(statsByField, "stats");
  return (
    <Container>
      <FieldDisplayWrapper>
        <Typography variant="subTitle">{fieldDisplay}</Typography>
      </FieldDisplayWrapper>
      <Border />
      <StatItemTableWrapper>
        <StatItemTable>
          {map(stats, (stat, index) => (
            <StatItem
              key={index}
              stat={stat}
              maxAverageDaysInInventory={maxAverageDaysInInventory}
            />
          ))}
        </StatItemTable>
      </StatItemTableWrapper>
    </Container>
  );
};

export default Stat;
