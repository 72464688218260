import React, {
  FC,
  forwardRef,
  HTMLAttributes, Ref,
  useCallback,
  useState
} from "react";
import styles from "./InputField.module.scss";
import classNames from "classnames";
import { MdPersonOutline, MdLockOutline } from "react-icons/md";

interface Props {
  type?: string;
  name?: string;
  label?: string;
  value?: string;
  className?: string;
  inputClassName?: string;
  onChange: (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

function getIcon(name?: string) {
  switch (name) {
    case "username":
      return <MdPersonOutline />;
    case "password":
      return <MdLockOutline />;
    default:
      return null;
  }
}

function InputField(
  {
    type = "text",
    name,
    label,
    value,
    className,
    inputClassName,
    ...otherProps
  }: Props & HTMLAttributes<HTMLInputElement>,
  ref: Ref<HTMLInputElement>
) {
  const [isFocus, setFocus] = useState(false);

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const icon = getIcon(name);

  return (
    <label
      className={classNames(
        styles.inputField,
        isFocus && styles.isFocus,
        className
      )}
    >
      {label && <span className={styles.label}>{label}</span>}
      {icon && <span className={styles.iconWrapper}>{icon}</span>}
      <input
        className={classNames(styles.input, inputClassName)}
        ref={ref}
        type={type}
        name={name}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        {...otherProps}
      />
    </label>
  );
}

export default forwardRef(InputField);
