import axios, { AxiosResponse } from "axios";
import produce from "immer";
import { Action, handleActions } from "redux-actions";
import createAsyncSagaAction from "../cores/createAsyncSagaAction";
import { createAsyncSagaReducerMap } from "../cores/createAsyncSagaReducerMap";
import { action, PayloadAction, PayloadMetaAction } from "typesafe-actions";
import {
  EnvelopPayload,
  Filter,
  InventoryRecord,
  Sort,
  StatsByField,
  Summary,
} from "../declaration/inventory";

export enum InventoryTypes {
  getInventoryRecords = "@inventory/getInventoryRecords",
  getFilter = "@inventory/getFilter",
  setSelectedFilter = "@inventory/setSelectedFilter",
  setSelectedSort = "@inventory/setSelectedSort",
  truncateInventory = "@inventory/truncateInventory",
  getStatsByField = "@inventory/getStatsByField",
  getInventorySummary = "@inventory/getInventorySummary",
}

export interface InventoryState {
  inventoryRecords: InventoryRecord[] | null;
  inventoryRecordsMeta: EnvelopPayload["meta"] | null;
  filter: { [key: string]: { [key: string]: Filter[] } };
  selectedFilter: { [key: string]: { [key: string]: string[] } };
  selectedSort: { [key: string]: { key: string; sort: Sort } };
  statsByFields: StatsByField[] | null;
  summary: Summary | null;
}

export const InventoryActions = {
  getInventoryRecords: createAsyncSagaAction(
    InventoryTypes.getInventoryRecords,
    (params: URLSearchParams) => {
      return axios.get(`/inventory_records/`, {
        params,
      });
    }
  ),
  getFilter: createAsyncSagaAction(
    InventoryTypes.getFilter,
    (model: string, field: string) => {
      return axios.get(
        `/inventory_records/filter/?model=${model}&field=${field}`
      );
    }
  ),
  setSelectedFilter: (model: string, field: string, value: string[]) =>
    action(InventoryTypes.setSelectedFilter, { model, field, value }),
  setSelectedSort: (model: string, field: string, sort: Sort) =>
    action(InventoryTypes.setSelectedSort, { model, field, sort }),
  truncateInventoryRecords: () => action(InventoryTypes.truncateInventory),
  getStatsByField: createAsyncSagaAction(
    InventoryTypes.getStatsByField,
    (model: string) => {
      return axios.get(`/inventory_records/stats_by_field/?model=${model}`);
    }
  ),
  getInventorySummary: createAsyncSagaAction(
    InventoryTypes.getInventorySummary,
    (model: string) => {
      return axios.get(`/inventory_records/summary/?model=${model}`);
    }
  ),
};

const initialState: InventoryState = {
  inventoryRecords: null,
  inventoryRecordsMeta: null,
  filter: {},
  selectedFilter: {},
  selectedSort: {},
  statsByFields: null,
  summary: null,
};

export default handleActions<InventoryState, any>(
  {
    ...createAsyncSagaReducerMap(InventoryTypes.getInventoryRecords, {
      onSuccess: (
        state,
        action: PayloadAction<
          string,
          AxiosResponse<EnvelopPayload<InventoryRecord>>
        >
      ) => {
        return produce(state, (draft) => {
          if (state.inventoryRecords) {
            draft.inventoryRecords = [
              ...state.inventoryRecords,
              ...action.payload.data.results,
            ];
          } else {
            draft.inventoryRecords = action.payload.data.results;
          }
          draft.inventoryRecordsMeta = action.payload.data.meta;
        });
      },
    }),
    ...createAsyncSagaReducerMap(InventoryTypes.getFilter, {
      onSuccess: (
        state,
        action: PayloadMetaAction<
          string,
          AxiosResponse<Filter[]>,
          [string, string]
        >
      ) => {
        const model = action.meta[0];
        const field = action.meta[1];

        return produce(state, (draft) => {
          draft.filter = {
            ...draft.filter,
            [model]: { [field]: action.payload.data },
          };
        });
      },
    }),
    [InventoryTypes.setSelectedFilter]: (
      state,
      action: Action<{ model: string; field: string; value: string[] }>
    ) => {
      return produce(state, (draft) => {
        const { model, field, value } = action.payload;
        draft.selectedFilter = {
          ...draft.selectedFilter,
          [model]: {
            ...draft.selectedFilter[model],
            [field]: value,
          },
        };
      });
    },
    [InventoryTypes.setSelectedSort]: (
      state,
      action: Action<{ model: string; field: string; sort: Sort }>
    ) => {
      return produce(state, (draft) => {
        const { model, field, sort } = action.payload;
        draft.selectedSort = {
          [model]: { key: field, sort },
        };
      });
    },
    [InventoryTypes.truncateInventory]: (state) => {
      return produce(state, (draft) => {
        draft.inventoryRecords = null;
        draft.inventoryRecordsMeta = null;
      });
    },
    ...createAsyncSagaReducerMap(InventoryTypes.getStatsByField, {
      onSuccess: (
        state,
        action: PayloadAction<string, AxiosResponse<StatsByField[]>>
      ) => {
        return produce(state, (draft) => {
          draft.statsByFields = action.payload.data;
        });
      },
    }),
    ...createAsyncSagaReducerMap(InventoryTypes.getInventorySummary, {
      onSuccess: (
        state,
        action: PayloadAction<string, AxiosResponse<Summary>>
      ) => {
        return produce(state, (draft) => {
          draft.summary = action.payload.data;
        });
      },
    }),
  },
  initialState
);
